import * as React from "react";
import "./Registration.scss";
import { ReactComponent as InfoIcon } from "../../../images/info.svg";
import { ReactComponent as CheckIcon } from "../../../images/check.svg";
import { ReactComponent as UserIcon } from "../../../images/user.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { changeDeliveryType, setRegistrationField } from "../../../actions/cart";
import Input from "../../commons/RamInput/Input";
import PhoneInput from "../../commons/RamInput/PhoneInput";
import EmailInput from "../../commons/RamInput/EmailInput";
import classNames from "classnames";
import { getNextDeliveryTime } from "../../../actions/product";
import SubmitButton from "../SubmitButton/SubmitButton";
import { declOfNum } from "../../../helpers/stringOperations";
import Hint from "../../commons/Hint/Hint";
import Disclamer from "../SubmitButton/Disclamer";
import OrganisationInfoWarning from "./OrganisationInfoWarning/OrganisationInfoWarning";
import AddressPanel from "../Address/AddressPanel";
import RadioGroup from "../../commons/RadioGroup/RadioGroup";
import CheckBoxItem from "../../commons/CheckBoxItem/CheckBoxItem";

import moment from "moment";
import DeliveryTimeSlots from "./DeliveryTimeSlots/DeliveryTimeSlots";
import { DELIVERY_TYPES } from "../../../constants";
import { setSystemErrorMessage } from "../../../actions/api";


const SHOW_HINT = false;

const AUTO_ORDER_TYPES = [
  { title: "Да, согласовать", value: true },
  { title: "Нет, я хочу убедиться в наличии всех товаров", value: false },
];
const { env } = global.config;

class Registration extends React.Component {
  state = {
    deliveryDate: null,
  };

  componentDidMount() {
    const { setRegistrationField, user, registration, getNextDeliveryTime, isCompany, deferredPaymentInfo } = this.props;
    const canUseDeferredPayment = deferredPaymentInfo?.canUseDeferredPayment;
    isCompany && canUseDeferredPayment
      ? getNextDeliveryTime(registration.paymentServiceId, registration.paymentMethod).then((res) => this.setDeliveryDate(moment(res.deliveryDate).format("dddd, D MMMM")))
      : getNextDeliveryTime(registration.paymentServiceId).then((res) => this.setDeliveryDate(moment(res.deliveryDate).format("dddd, D MMMM")));

    const { email, phoneNumber, firstName, middleName, lastName } = user;
    const phoneCodeIsRight = phoneNumber.includes("(");

    const formatedPhoneNumber = !phoneCodeIsRight
      ? phoneNumber.slice(0, 3) + "(" + phoneNumber.slice(3, 6) + ")" + phoneNumber.slice(6, 16)
      : phoneNumber;

    const fullName = `${lastName || ""} ${firstName || ""} ${middleName || ""}`;
    setRegistrationField("recipientFullName", registration.recipientFullName || fullName.trim());
    setRegistrationField("recipientPhone", registration.recipientPhone || formatedPhoneNumber);
    setRegistrationField("recipientEmail", registration.recipientEmail || email);
    setRegistrationField("paymentMethod", canUseDeferredPayment ? "DeferredPayment" : "Interbank");
    !isCompany && setRegistrationField("paymentServiceId", "Qiwi");
  }

  componentDidUpdate(prevProps, prevState) {
    const { registration, getNextDeliveryTime, deferredPaymentInfo, setSystemErrorMessage, deliveryType, user, setRegistrationField } = this.props;
    const canUseDeferredPayment = deferredPaymentInfo?.canUseDeferredPayment;
    if (prevProps.registration.paymentMethod !== registration.paymentMethod) {
      setSystemErrorMessage();
      registration.paymentMethod === "DeferredPayment" && canUseDeferredPayment
        ? getNextDeliveryTime(registration.paymentServiceId, registration.paymentMethod).then((res) => this.setDeliveryDate(moment(res.deliveryDate).format("dddd, D MMMM")))
        : getNextDeliveryTime(registration.paymentServiceId).then((res) => this.setDeliveryDate(moment(res.deliveryDate).format("dddd, D MMMM")));
    }

    if (prevProps.deliveryType !== deliveryType) {
      const { email, phoneNumber, firstName, middleName, lastName } = user;
      const phoneCodeIsRight = phoneNumber.includes("(");

      const formatedPhoneNumber = !phoneCodeIsRight
        ? phoneNumber.slice(0, 3) + "(" + phoneNumber.slice(3, 6) + ")" + phoneNumber.slice(6, 16)
        : phoneNumber;

      const fullName = `${lastName || ""} ${firstName || ""} ${middleName || ""}`;
      setRegistrationField("recipientFullName", registration.recipientFullName || fullName.trim());
      setRegistrationField("recipientPhone", registration.recipientPhone || formatedPhoneNumber);
      setRegistrationField("recipientEmail", registration.recipientEmail || email);

    }

  }

  setDeliveryDate = (deliveryDate) => {
    this.setState({ deliveryDate });
  };

  handleFieldChange = (fieldName) => (value) => {
    const { setRegistrationField, handleValidateContacts } = this.props;
    setRegistrationField(fieldName, value);
    handleValidateContacts(fieldName, value);
  };

  handlePaymentDelaySelect = (delayValue, fieldName, value) => {
    this.handleFieldChange(fieldName)(value);
    const method = value === "Tochka" ? "Interbank" : "";
    delayValue ? this.handleFieldChange("paymentMethod")("DeferredPayment") : this.handleFieldChange("paymentMethod")(method);
  };

  renderAccontInfo = () => {
    const { account, isMobile } = this.props;
    if (!account) return null;
    const { legalName = "", legalAddress = "", inn = "", kpp = "", companyType } = account;
    const selfEmployedPerson = companyType.code === "SelfEmployedPerson";

    return (
      <div>
        <div className="header">
          <h3 className="header-title">Реквизиты организации</h3>
          {SHOW_HINT && <Hint isMobile={isMobile} />}
        </div>
        <div className="company-info">
          <h4>{legalName}</h4>
          <div className="row">
            <div className="label-data">
              <div className="label">ИНН</div>
              <div className="data">{inn}</div>
            </div>
            {!selfEmployedPerson && (
              <div className="label-data">
                <div className="label">КПП</div>
                <div className="data">{kpp}</div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="label-data">
              <div className="label">Юридический адрес </div>
              <div className="data">{legalAddress}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderAutoCompleteOrder() {
    const { registration } = this.props;

    return (
      <div className="auto-order">
        <div className="header with-sub ">
          <h3 className="header-title">Согласовать автоматически заказ ?</h3>
          <div className="sub-title">После сборки заказа количество весового товара может измениться </div>
        </div>
        <div className="row-line">
          <RadioGroup value={registration.useAutoApproval} onChange={(v) => this.handleFieldChange("useAutoApproval")(v)} selectValues={AUTO_ORDER_TYPES} />
        </div>
      </div>
    );
  }

  handleSwitchType = (value) => {
    this.props.changeDeliveryType(value);
  }

  render() {
    const { registration, deferredPaymentInfo, isMobile, isTablet, nextStep, isCompany, shouldAccomplishOrder, history, submitDisabled, resetValidation, validContacts, deliveryType, deliveryInterval, setDeliveryInterval, buttonLoading } =
      this.props;
    const { deliveryDate } = this.state;
    const shouldAccomplishRegistration = shouldAccomplishOrder && isCompany;
    const daysForDelay = ` ${deferredPaymentInfo?.daysForDeferredPayment} ${declOfNum(deferredPaymentInfo?.daysForDeferredPayment, ["день", "дня", "дней"])}`;
    const showDefferedPayment = deferredPaymentInfo?.canUseDeferredPayment;
    const paymentDelay = registration.paymentMethod === "DeferredPayment";
    const deliveryByPickUp = deliveryType === DELIVERY_TYPES[1];
    const dateToDeliver = deliveryByPickUp ? deliveryInterval : deliveryDate;

    if (shouldAccomplishRegistration)
      return (
        <div className="registration">
          <div className="block">
            <OrganisationInfoWarning isMobile={isMobile} onClick={() => history.push("/profile/organisation")} />
          </div>
        </div>
      );

    return (
      <div className="registration">
        <div className="block">
          <div className="header first">
            <h3 className="header-title">Способ оплаты</h3>
            {SHOW_HINT && <Hint isMobile={isMobile} />}
          </div>
          <div className="hint-text">
            <InfoIcon />
            <span>Стоимость заказа может измениться после того как поставщики подтвердят наличие и уточнят фактический вес товара.</span>
          </div>
          <div className="row-line pay-methods">
            {isCompany && showDefferedPayment && (
              <div
                className={classNames({
                  "shadow-box pay-method": true,
                  active: registration.paymentServiceId === "Tochka" && paymentDelay,
                })}
                onClick={() => this.handlePaymentDelaySelect(true, "paymentServiceId", "Tochka")}
              >
                <CheckBoxItem checked={registration.paymentServiceId === "Tochka" && paymentDelay} />
                <div className="pay-method-captions">
                  <span>
                    <span className="green">Без % отсрочка </span>
                    <span>на {daysForDelay}</span>
                  </span>
                  <span className="gray">Оплата по договор-счету</span>
                </div>
              </div>
            )}
            {isCompany && !showDefferedPayment && (
              <div className="shadow-box pay-method disabled">
                <CheckBoxItem checked={false} />
                <div className="pay-method-captions">
                  <span className="caption">Без % отсрочка </span>
                  <div className="pay-method-text-container">
                    <span className="text">Заблокирована</span>
                    <div className="payment-delay-hint">
                      <InfoIcon />

                      <p className="payment-delay-hint-text">
                        Для разблокировки оформите первый заказ по предоплате или обратитесь по номеру
                        <a href="tel:+78006002580" className="payment-delay-hint-phone">
                          8 800 600 25 80
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!isCompany && <div
              className={classNames({
                "shadow-box pay-method": true,
                active: registration.paymentServiceId === "Qiwi",
              })}
              onClick={() => this.handlePaymentDelaySelect(false, "paymentServiceId", "Qiwi")}
            >
              <CheckBoxItem checked={registration.paymentServiceId === "Qiwi"} />
              <div className="pay-method-captions">
                <span>Банковской картой</span>
                <span className="gray">Онлайн картой</span>
              </div>
            </div>}

            {isCompany && (
              <div
                className={classNames({
                  "shadow-box pay-method": true,
                  active: registration.paymentServiceId === "Tochka" && !paymentDelay,
                })}
                onClick={() => this.handlePaymentDelaySelect(false, "paymentServiceId", "Tochka")}
              >
                <CheckBoxItem checked={registration.paymentServiceId === "Tochka" && !paymentDelay} />
                <div className="pay-method-captions">
                  <span>Предоплата по счету</span>
                  <span className="gray">Оплата по договор-счету</span>
                </div>
              </div>
            )}

            {/* DISABLED
            <div
              className={classNames({
                "shadow-box pay-method": true,
                active: registration.paymentServiceId === "Qiwi",
              })}
              onClick={() => this.handlePaymentDelaySelect(false, "paymentServiceId", "Qiwi")}
            >
              {selectBox(registration.paymentServiceId === "Qiwi")}
              <div>
                <div>Предоплата картой</div>
                <div className="payments">
                  <VisaIcon />
                  <MastercardIcon />
                  <MirIcon />
                </div>
              </div>
            </div> */}
          </div>
          {/* {!!isQiwiPayment && (
            <div className="row-line credit-cards">
              <QiwiIcon />
              <VisaIcon />
              <MastercardIcon />
              <MirIcon />
            </div>
          )} */}
          <h3 className="header-title">Способ получения</h3>
          <div className="address-delivery-types">
            <div className={`address-delivery-type ${deliveryType === DELIVERY_TYPES[0] ? "selected" : ""}`} onClick={() => this.handleSwitchType(DELIVERY_TYPES[0])} ><CheckBoxItem checked={deliveryType === DELIVERY_TYPES[0]} onClick={() => this.handleSwitchType(DELIVERY_TYPES[0])} />
              <span>Доставка</span>
            </div>
            {env !== "dachnik" && <div className={`address-delivery-type ${deliveryByPickUp ? "selected" : ""}`} onClick={() => this.handleSwitchType(DELIVERY_TYPES[1])}><CheckBoxItem checked={deliveryByPickUp} onClick={() => this.handleSwitchType(DELIVERY_TYPES[1])} /> <span>Самовывоз</span></div>}
          </div>
          <AddressPanel date={dateToDeliver} registration={registration} resetValidation={resetValidation} deliveryType={deliveryType} />
          {deliveryByPickUp && <DeliveryTimeSlots selectedInterval={deliveryInterval} setSelectedInterval={setDeliveryInterval} />}
          
          <div className="row-line w100">
            <Input
              classNames="details"
              title="Комментарий к доставке, например, ресторан “Гуси-лебеди"
              textarea={true}
              value={registration.details}
              onChange={this.handleFieldChange("details")}
            />
          </div>
          {/* <div className="row-line w100 last">
                    <CheckBox checked={registration.rememberAddress} onChange={this.handleFieldChange('rememberAddress')} label="Сохранить адрес" />
                </div> */}
          {paymentDelay && this.renderAutoCompleteOrder()}
          <div className="header ">
            <h3 className="header-title">Получатель</h3>
            {SHOW_HINT && <Hint isMobile={isMobile} />}
          </div>
          {!isMobile && (
            <div>
              <div className="row-line w50">
                <Input
                  title="Имя *"
                  leftImg={<UserIcon />}
                  value={registration.recipientFullName}
                  onChange={this.handleFieldChange("recipientFullName")}
                  hasError={!validContacts.recipientFullName}
                  errorText="Введите имя"
                />
                <PhoneInput
                  title="Телефон *"
                  mobileNumber={registration.recipientPhone}
                  onPhoneChange={this.handleFieldChange("recipientPhone")}
                  hasError={!validContacts.recipientPhone}
                  errorText="Введите корректный номер телефона"
                />
              </div>
              <div className="row-line w50 single">
                <EmailInput
                  title="E-mail *"
                  value={registration.recipientEmail}
                  onChange={this.handleFieldChange("recipientEmail")}
                  hasError={!validContacts.recipientEmail}
                  errorText="Введите корректный адрес e-mail"
                />
              </div>
              {isTablet && <Disclamer />}
            </div>
          )}
          {isMobile && (
            <div>
              <div className="row-line w100">
                <Input
                  title="Имя *"
                  leftImg={<UserIcon />}
                  value={registration.recipientFullName}
                  onChange={this.handleFieldChange("recipientFullName")}
                  hasError={!validContacts.recipientFullName}
                  errorText="Введите имя"
                />
              </div>
              <div className="row-line w100">
                <PhoneInput
                  title="Телефон *"
                  mobileNumber={registration.recipientPhone}
                  onPhoneChange={this.handleFieldChange("recipientPhone")}
                  hasError={!validContacts.recipientPhone}
                  errorText="Введите корректный номер телефона"
                />
              </div>
              <div className="row-line w100">
                <EmailInput
                  title="E-mail *"
                  value={registration.recipientEmail}
                  onChange={this.handleFieldChange("recipientEmail")}
                  hasError={!validContacts.recipientEmail}
                  errorText="Введите корректный адрес e-mail"
                />
              </div>
              <Disclamer />
            </div>
          )}
          {isCompany && this.renderAccontInfo()}

          {/* <div className="header mb20">
            <h3 className="header-title">Предполагаемая дата доставки</h3>
            <Hint isMobile={isMobile} />
          </div>
          {deliveryDate && (
            <div className="delivery-box">
              <div className="icon">
                <FoodTrackIcon />
              </div>
              <div className="date-time">
                <div>{deliveryDate}</div>
                <div className="gray">с 10:00 до 18:00</div>
              </div>
            </div>
          )} */}

          {!isMobile && !isTablet && <SubmitButton nextStep={nextStep} disabled={submitDisabled} loading={buttonLoading} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  registration: state.cart.registration,
  deliveryType: state.cart.deliveryType,
  user: state.profile.user,
  account: state.profile.account,
  isCompany: state.profile.isCompany,
  deferredPaymentInfo: state.profile.deferredPaymentInfo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRegistrationField: setRegistrationField,
      getNextDeliveryTime: getNextDeliveryTime,
      setSystemErrorMessage: setSystemErrorMessage,
      changeDeliveryType: changeDeliveryType
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
